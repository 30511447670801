// @ts-check
import React, { useEffect, useState, useMemo, Fragment, useRef } from 'react'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import {
  Container,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  Backdrop,
  Tabs,
  Tab,
} from '@material-ui/core'
import { format } from 'date-fns'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import {
  SurveyBreadcrumb,
  InlineEditableValue,
  CustomIcon,
  SwapIcon,
  RightChevronIcon,
  Button,
  Loading,
  FieldDataTypeMenuSection,
  NestedMenuItem,
  Modal,
  MODALS,
  Typography,
} from '~/legacy/components'
import {
  DeleteIcon,
  LockIcon,
  MenuSelectedItemIcon,
  TableClearIcon,
  TextChevronDown,
  LogoIcon,
} from '~/legacy/components/svgs'
import {
  EditableStringCell,
  EditableNumberCell,
  EditableDateCell,
  EditableMultilineStringCell,
} from '~/legacy/components/tableComponents'
import {
  caseInsensitiveFindInArray,
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_VALIDATORS,
  BULK_IMPORT_HELPERS,
  caseInsensitiveCompare,
} from '~/legacy/utils'
import { useApiHelper } from '~/legacy/utils/hooks'
import { LEASE_PROJECT_BY_ID } from '~/legacy/consts'

export default function SurveyBulkImport({
  survey,
  removedHeaderIndices,
  setRemovedHeaderIndices,
  validatedListings,
  setValidatedListings,
  orderedListingFields,
  csvHeaderIndexLookup,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
  mappedStandardFields,
  setMappedStandardFields,
  uploadedCsvFile,
}) {
  const apiHelper = useApiHelper()
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [removeColumnHeader, setRemoveColumnHeader] = useState(null)
  const [removeColumnHeaderLoading, setRemoveColumnHeaderLoading] =
    useState(false)

  const [selectedTab, setSelectedTab] = useState('buildings')
  const location = useLocation()
  const isDatabase = location.pathname.includes('databases')

  const backDropText = `Your ${
    isDatabase ? 'database' : 'survey'
  } will be prepared shortly`

  const closeRemoveColumnHeaderModal = () => {
    setRemoveColumnHeaderLoading(false)
    setRemoveColumnHeader(null)
  }

  const updateLoadedListingFields = (listingFieldIndex, newListingField) => {
    setLoadedListingFieldsObj({
      ...loadedListingFieldsObj,
      [listingFieldIndex]: newListingField,
    })
  }

  // Sticky header and dynamically adding/removing the bottom border
  const [isSticky, setIsSticky] = useState(false)
  const ref = React.createRef()
  const mainContainerRef = useRef(null)
  // mount
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )

    observer.observe(cachedRef)

    // unmount
    return () => observer.unobserve(cachedRef)
  }, [])

  // Make sure we reset the scroll when changing tabs
  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo(0, 0)
    }
  }, [selectedTab])

  const {
    buildingFields,
    listingFields,
    buildingFieldsErrors,
    listingFieldsErrors,
  } = useMemo(() => {
    const returnData = {
      buildingFields: [],
      listingFields: [],
      buildingFieldsErrors: 0,
      listingFieldsErrors: 0,
    }

    const buildingFieldSet = new Set()
    const listingFieldSet = new Set()

    orderedListingFields.forEach((field) => {
      if (
        field.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
      ) {
        returnData.buildingFields.push(field)

        // Check duplicates
        if (buildingFieldSet.has(field.displayName)) {
          returnData.buildingFieldsErrors++
        } else {
          buildingFieldSet.add(field.displayName)
          if (field.error) {
            returnData.buildingFieldsErrors++
          }
        }
      }

      if (field.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id) {
        returnData.listingFields.push(field)

        // Check duplicates
        if (listingFieldSet.has(field.displayName)) {
          returnData.listingFieldsErrors++
        } else {
          listingFieldSet.add(field.displayName)
          if (field.error) {
            console.log('has error', field)
            returnData.listingFieldsErrors++
          }
        }
      }
    })

    return returnData
  }, [orderedListingFields])

  const { data, buildingErrors, listingErrors } = useMemo(() => {
    if (!validatedListings || !validatedListings.length) {
      return { data: [], buildingErrors: 0, listingErrors: 0 }
    }

    const buildingIndeces = buildingFields.map((field) => field.index)
    const listingIndeces = listingFields.map((field) => field.index)

    let buildingErrors = 0
    let listingErrors = 0

    validatedListings.forEach((listing) => {
      Object.keys(listing).forEach((key) => {
        const value = listing[key]
        if (buildingIndeces.includes(Number(key)) && value.error) {
          buildingErrors++
        }

        if (listingIndeces.includes(key) && value.error) {
          listingErrors++
        }
      })
    })

    const buildingsMap = groupBy(
      validatedListings,
      `${BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index}.value.GOOGLE_ADDRESS.address`
    )

    const data = Object.keys(buildingsMap).map((address) => {
      const rows = buildingsMap[address]
      const building = rows[0]
      const listings = rows

      return { address, building, listings }
    })

    return { data, buildingErrors, listingErrors }
  }, [validatedListings, buildingFields, listingFields])

  const buildingConflicts = buildingErrors + buildingFieldsErrors
  const listingConflicts = listingErrors + listingFieldsErrors

  const totalConflicts = buildingConflicts + listingConflicts
  const importListingsDisabled =
    !validatedListings || !validatedListings.length || totalConflicts !== 0

  const buildingCountString =
    data.length === 1 ? '1 Building' : `${data.length} Buildings`

  const listingCountString =
    validatedListings.length === 1
      ? '1 Space'
      : `${validatedListings.length} Spaces`

  const buildingConflictsCountString =
    buildingConflicts === 1 ? '1 Conflict' : `${buildingConflicts} Conflicts`

  const listingConflictsCountString =
    listingConflicts === 1 ? '1 Conflict' : `${listingConflicts} Conflicts`

  return (
    <Container
      maxWidth={false}
      className="flex flex-col p-0 pb-8 max-w-full w-full overflow-auto"
      ref={mainContainerRef}
    >
      {/* Sticky horizontal only */}
      <div className="w-full mx-auto px-8 bg-white sticky left-0 z-[1]">
        <div className="w-full mx-auto mt-3 flex items-center min-h-11">
          <SurveyBreadcrumb surveyId={survey.id} surveyName={survey.name} />

          <Button
            className={clsx(
              'ml-auto h-9',
              importListingsDisabled && 'text-[#666]'
            )}
            color="primary"
            customHeight
            disabled={importListingsDisabled}
            onClick={() => {
              setBackdropOpen(true)
              const { listings, customFields } = getDataForSave({
                survey,
                loadedListingFieldsObj,
                validatedListings,
              })

              // Save the listings, then forward to the SDP
              apiHelper
                .addListingsBulk({
                  listings,
                  bulkImportCsvFileId: !!uploadedCsvFile && uploadedCsvFile.id,
                  surveyId: survey.id,
                  customFields,
                })
                .then((results) => {
                  if (results && results.length) {
                    if (isDatabase) {
                      window.location.reload()
                    } else {
                      // TODO: Make SPA friendly by instead just reloading the survey, and closing all of the import stuff.
                      window.location.replace(
                        LEASE_PROJECT_BY_ID.replace(':id', survey.id)
                      )
                    }
                  } else {
                    setBackdropOpen(false)
                  }
                })
            }}
          >
            Import Data
          </Button>
        </div>

        <div className="w-full mx-auto">
          <div className="flex items-center pt-6 px-0 pb-4">
            <div>
              <Typography variant="h1">{survey.name}</Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        className={clsx(
          'sticky px-8 top-[-1px] left-0 z-[3] bg-white h-[54px]',
          isSticky &&
            'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:bg-[#E0E0E0] after:z-[50]'
        )}
      >
        {/* FIXME: This is a hack to avoid showing the table content behing the sticky header */}
        <div
          className={clsx(
            'absolute h-4 bg-white left-8 right-0 top-[54px] z-[-1]',
            isSticky ? 'block' : 'hidden'
          )}
        />

        <Tabs
          className="h-full shadow-border"
          classes={{ flexContainer: 'h-[54px]' }}
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          indicatorColor="primary"
        >
          <Tab
            className="min-w-[unset] opacity-100"
            value="buildings"
            label="Buildings"
          />

          <Tab
            className="min-w-[unset] opacity-100"
            value="spaces"
            label="Spaces"
          />
        </Tabs>
      </div>

      {selectedTab === 'buildings' ? (
        <>
          <div
            className={clsx(
              'flex px-8 min-h-[68px] w-full bg-white items-center sticky left-0'
              // isSticky && 'border-0 border-b border-solid border-[#e0e0e0]'
            )}
            ref={ref}
          >
            <Typography variant="h3" className="leading-[14px]">
              {buildingCountString}
            </Typography>
            <Typography
              variant="h3"
              className={clsx(
                'text-sm text-[#666] ml-4 leading-[14px]',
                buildingConflicts !== 0 && 'text-[#DD421A]'
              )}
            >
              {buildingConflictsCountString}
            </Typography>
          </div>

          <div className="w-full ml-8">
            {/*
              This is a hack to avoid showing the scrollable content behind the sticky column
              It creates a vertical bar that covers the space between the left side of the page and the table
            */}
            <div className="absolute top-[114px] left-0 bottom-4 w-8 bg-white z-10" />

            {data.length && (
              <TableContainer className="flex-none overflow-visible w-full h-full">
                <Table size="small" className="border-separate pr-8">
                  <TableHead className="sticky top-[66px] z-[3]">
                    <TableRow>
                      {buildingFields.map((header, index) => (
                        <FieldHeaderCell
                          key={header.index}
                          header={header}
                          index={index}
                          numColumns={buildingFields.length}
                          updateLoadedListingFields={updateLoadedListingFields}
                          mappedStandardFields={mappedStandardFields}
                          loadedListingFieldsObj={loadedListingFieldsObj}
                          setLoadedListingFieldsObj={setLoadedListingFieldsObj}
                          setMappedStandardFields={setMappedStandardFields}
                          setRemoveColumnHeader={setRemoveColumnHeader}
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      /* eslint-disable react/no-array-index-key */
                      <BuildingFieldRow
                        key={index}
                        index={index}
                        rowCount={data.length}
                        orderedListingFields={buildingFields}
                        listing={row.building}
                        validatedListings={validatedListings}
                        setValidatedListings={setValidatedListings}
                        csvHeaderIndexLookup={csvHeaderIndexLookup}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </>
      ) : null}

      {selectedTab === 'spaces' ? (
        <>
          <div
            className={clsx(
              'flex px-8 min-h-[68px] w-full bg-white items-center sticky left-0'
              // isSticky && 'border-0 border-b border-solid border-[#e0e0e0]'
            )}
            // ref={ref}
          >
            <Typography variant="h3" className="leading-[14px]">
              {listingCountString}
            </Typography>
            <Typography
              variant="h3"
              className={clsx(
                'text-sm text-[#666] ml-4 leading-[14px]',
                listingConflicts !== 0 && 'text-[#DD421A]'
              )}
            >
              {listingConflictsCountString}
            </Typography>
          </div>

          <div className="w-full ml-8">
            {/*
              This is a hack to avoid showing the scrollable content behind the sticky column
              It creates a vertical bar that covers the space between the left side of the page and the table
            */}
            <div className="absolute top-[114px] left-0 bottom-4 w-8 bg-white z-10" />

            {data.length && (
              <TableContainer className="h-full overflow-visible">
                <Table size="small" className="border-separate pr-8">
                  <TableHead className="sticky top-[66px] z-[3]">
                    <TableRow>
                      {listingFields.map((header, index) => (
                        <FieldHeaderCell
                          key={header.index}
                          header={header}
                          index={index}
                          numColumns={listingFields.length}
                          updateLoadedListingFields={updateLoadedListingFields}
                          mappedStandardFields={mappedStandardFields}
                          loadedListingFieldsObj={loadedListingFieldsObj}
                          setLoadedListingFieldsObj={setLoadedListingFieldsObj}
                          setMappedStandardFields={setMappedStandardFields}
                          setRemoveColumnHeader={setRemoveColumnHeader}
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      /* eslint-disable react/no-array-index-key */
                      <SpaceFieldRow
                        key={index}
                        orderedListingFields={listingFields}
                        listing={row}
                        validatedListings={validatedListings}
                        setValidatedListings={setValidatedListings}
                        csvHeaderIndexLookup={csvHeaderIndexLookup}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </>
      ) : null}

      <Backdrop className="text-white z-50" open={backdropOpen}>
        <div className="flex flex-col items-center">
          <Loading color="inherit" isLoading />
          <Typography className="text-lg">Importing data</Typography>
          <Typography>{backDropText}</Typography>
        </div>
      </Backdrop>

      <Modal
        content={MODALS.CONFIRM_MODAL}
        open={!!removeColumnHeader}
        onClose={closeRemoveColumnHeaderModal}
        childProps={{
          onConfirm: () => {
            setRemoveColumnHeaderLoading(true)
            // Add the header to remove to our set of removed headers
            const newRemovedHeaderIndices = new Set(removedHeaderIndices)
            newRemovedHeaderIndices.add(removeColumnHeader.index)
            setRemovedHeaderIndices(newRemovedHeaderIndices)
            closeRemoveColumnHeaderModal()
          },
          title: 'Are you sure?',
          text: 'Are you sure you want to remove this column from your table? This can’t be undone.',
          confirmButtonLabel: 'Remove',
          loading: removeColumnHeaderLoading,
        }}
      />
    </Container>
  )
}

// Update a field value. The user has entered something new!
// We update the field when setting the value, and update the
//   already validated fields, so we don't have to use state management and
//   validate every single other cell.
const updateFieldValue = (
  header,
  listing,
  index,
  newValue,
  validatedListings,
  setValidatedListings,
  callback = () => {} // TODO: need this?
) => {
  const newValidatedListings = [...validatedListings]
  // Use the field type's validator function to get the new validated value, including any errors
  const newValidatedListingField = header.fieldDataType.validator(newValue)

  // replace the old value on the listing with the new
  newValidatedListings[index] = {
    ...listing,
    [header.index]: newValidatedListingField,
  }

  // Update the validated listings
  setValidatedListings(newValidatedListings)
  callback()
}

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
  csvHeaderIndexLookup,
}) => {
  let listingAddress = ''
  let listingCity = ''
  let listingState = ''
  let listingZipcode = ''

  const getAddressValue = () => {
    if (!cell.error) {
      listingAddress = cell.value.GOOGLE_ADDRESS.address
      listingCity = cell.value.GOOGLE_ADDRESS.city
      listingState = cell.value.GOOGLE_ADDRESS.state
      listingZipcode = cell.value.GOOGLE_ADDRESS.zipcode
      // TODO: only need the GOOGLE_ADDRESS here for display...
      return {
        GOOGLE_ADDRESS: { ...cell.value.GOOGLE_ADDRESS },
        ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
      }
    }
    return null
  }

  const addressValue = getAddressValue()

  // If there's no error, then use the google address parts. Else, use whatever was
  //   provided in the csv.
  if (cell.error) {
    listingAddress = cell.csvValue.address
    listingCity = cell.csvValue.city
    listingState = cell.csvValue.state
    listingZipcode = cell.csvValue.zipcode
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={(data, newerValue) => {
        // Validate the address and update the listing's value.
        // The address validator has a slightly different signature than then rest of
        //   the fields so we just write it out here rather than using updateFieldValue()
        const newValidatedListings = [...validatedListings]

        const newValidatedListingField = BULK_IMPORT_VALIDATORS.validateAddress(
          newerValue,
          listing,
          csvHeaderIndexLookup
        )

        const newValidatedListing = {
          ...listing,
          [header.index]: newValidatedListingField,
        }
        newValidatedListings[index] = newValidatedListing

        // Update the validated listings
        setValidatedListings(newValidatedListings)
      }}
      value={addressValue || null}
      // TODO: This is a huge mess...
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      displayValueOverride={
        listingAddress || listingState || listingCity || listingZipcode
          ? {
              address: listingAddress,
              state: listingState,
              city: listingCity,
              zipcode: listingZipcode,
            }
          : null
      }
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
const NumericFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
const DateFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

const ListSemicolonCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          BULK_IMPORT_HELPERS.joinListString(newValue),
          validatedListings,
          setValidatedListings
        )
      }
    />
  )
}

const StringCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)

const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
    '& .MuiInputBase-input': {
      height: '22px',
    },
  },
})(TextField)

// The header displayed on the table.
const FieldHeaderCell = ({
  header,
  index,
  numColumns,
  updateLoadedListingFields,
  mappedStandardFields,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
  setMappedStandardFields,
  setRemoveColumnHeader,
}) => {
  // We keep track of the user changes to the header, and when the menu closes, save and persist those changes
  const [newHeader, setNewHeader] = useState({ ...header })
  const [newHeaderError, setNewHeaderError] = useState('')
  const theme = useTheme()

  const isLast = index === numColumns - 1
  const isSpacesHeader =
    header.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE.id

  useEffect(() => {
    if (header) {
      setNewHeader({ ...header })
    } else {
      setNewHeader({})
    }
  }, [header])

  // Menu stuff
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const anchorRef = React.useRef(null)
  const handleClick = () => {
    setAnchorEl(anchorRef.current)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    if (!newHeaderError) {
      // Only update if there was a change, just to speed things up...
      if (newHeader.displayName !== header.displayName) {
        updateLoadedListingFields(newHeader.index, newHeader)
      }
    }

    closeMenu()
  }

  // Make sure we are properly setting the error message anytime the new header changes as the user modifies it
  useEffect(() => {
    if ((newHeader && !newHeader.reserved) || !newHeader) {
      if (!newHeader.displayName) {
        setNewHeaderError('Enter a name')
      } else if (
        caseInsensitiveFindInArray(
          BULK_IMPORT_CONSTANTS.NEW_RESERVED_FIELDS,
          'displayName',
          newHeader.displayName
        )
      ) {
        setNewHeaderError('This name is reserved')
      } else {
        setNewHeaderError('')
      }
    } else {
      setNewHeaderError('')
    }
  }, [newHeader])

  // Get the component for the icon from the field type
  const IconComponent = newHeader.fieldDataType.icon

  return (
    <TableCell
      align="center"
      className={clsx(
        'bg-[#F9F9F9] h-12 max-h-12 px-4 py-0',
        'border-0 border-solid border-[#E0E0E0] border-l border-t',
        'first:rounded-tl first:sticky first:left-8 first:border-r-2 first:z-[1] last:rounded-tr last:border-r',
        isSpacesHeader && 'border-b first:rounded-bl last:rounded-br',
        index === 1 && 'border-l-0'
      )}
      ref={anchorRef}
    >
      <Tooltip
        title={newHeader.error || ''}
        disableFocusListener
        disableTouchListener
      >
        <div
          className={clsx(
            'text-[#666] flex items-center cursor-pointer px-2 py-1.5',
            newHeader.error && 'bg-[rgba(221,66,26,0.15)]'
          )}
          onClick={handleClick}
        >
          {newHeader.reserved ? (
            <LogoIcon className="mr-2" />
          ) : (
            <IconComponent className="mr-2" />
          )}
          <Typography
            noWrap
            variant="body2"
            style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
          >
            {header.displayName.toUpperCase()}
          </Typography>
          <TextChevronDown className="text-[#666]" />
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        className="w-[240px]"
        elevation={2}
        // Open bottom centered
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isLast ? 'right' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isLast ? 'right' : 'center',
        }}
        PaperProps={{
          style: {
            width: '240px',
            minWidth: '240px',
            maxHeight: '375px',
          },
        }}
      >
        <div className="text-[#666] pt-3 pb-1.5 px-3 items-center">
          <Typography variant="h3" className="leading-3 font-med">
            Field Name
          </Typography>
        </div>
        <div className="flex pt-0 px-3 items-center">
          {/* The name of the field */}
          {newHeader.reserved || newHeader.disableAllEditing ? (
            <>
              <Typography className="pt-1.5 pb-[7px]">
                {newHeader.displayName}
              </Typography>
              <LockIcon className="ml-auto" />
            </>
          ) : (
            <WhiteTextField
              fullWidth
              id="standard-basic"
              value={newHeader.displayName}
              variant="standard"
              color="primary"
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                endAdornment:
                  newHeader.reserved || newHeader.disableAllEditing ? (
                    ''
                  ) : (
                    <InputAdornment position="end">
                      <TableClearIcon
                        className="cursor-pointer mr-[-5px] text-[#e0e0e0]"
                        onClick={() => {
                          setNewHeader({ ...newHeader, displayName: '' })
                        }}
                        onMouseDown={(event) => event.preventDefault()}
                      />
                    </InputAdornment>
                  ),
                autoFocus: true,
              }}
              error={!!newHeaderError}
              helperText={newHeaderError || ''}
              FormHelperTextProps={{ style: { ...theme.typography.h5 } }}
              onChange={(event) =>
                setNewHeader({ ...newHeader, displayName: event.target.value })
              }
            />
          )}
        </div>
        {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
          <Divider sx={{ my: 0.5 }} className="items-center mx-3 px-0" />
        )}

        {/* Menu section for the field data type. Potentially editable */}
        {!!(!newHeader.reserved && !newHeader.disableAllEditing) && (
          <FieldDataTypeMenuSection
            classesIn={{
              fieldHeaderMenuSection: 'pt-8',
            }}
            onClick={(newFieldType) => {
              updateLoadedListingFields(header.index, {
                ...header,
                fieldDataType: newFieldType,
              })
              closeMenu()
            }}
            fieldDataTypeId={newHeader.fieldDataType.id}
          />
        )}

        {/* Field types - space or building */}
        {!!(!newHeader.reserved && !newHeader.disableAllEditing) && [
          <div
            key="fieldTypeKey"
            className="text-[#666] pb-1.5 pt-5 px-3 items-center"
          >
            <Typography variant="h3" className="leading-3 font-med">
              Field Type
            </Typography>
          </div>,
          BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_TYPES_ORDERED.map(
            (fieldType) => {
              const FieldIconComponent = fieldType.icon
              return (
                <MenuItem
                  className="px-3 items-center"
                  disableGutters
                  key={fieldType.id}
                  onClick={() => {
                    updateLoadedListingFields(newHeader.index, {
                      ...newHeader,
                      fieldType,
                    })
                    closeMenu()
                  }}
                >
                  <FieldIconComponent className="mr-3 text-[#111]" />
                  <Typography color="textPrimary" variant="h3">
                    {fieldType.name}
                  </Typography>
                  {fieldType.id === newHeader.fieldType.id && (
                    <MenuSelectedItemIcon className="ml-auto text-[#666]" />
                  )}
                </MenuItem>
              )
            }
          ),
        ]}

        {!newHeader.disableAllEditing && [
          <div
            key="fieldMatchingKey"
            className={clsx(
              'pb-1.5 text-[#666] px-3 items-center',
              !(!newHeader.reserved && !newHeader.disableAllEditing)
                ? 'pt-8'
                : 'pt-5'
            )}
          >
            <Typography variant="h3" className="leading-3 font-med">
              Field Matching
            </Typography>
          </div>,
          <NestedMenuItem
            key="changeFieldMatchMenuItemKey"
            MenuProps={{
              PaperProps: {
                style: {
                  width: '240px',
                  minWidth: '240px',
                  marginTop: '-8px', // align the nested modal by moving it up by the amount of top padding in the menu
                },
              },
              elevation: 2,
            }}
            disableGutters
            className="px-3 items-center"
            label={
              <div className="flex">
                <SwapIcon className="mr-3 text-[#111]" />
                <Typography variant="h3">Change field match</Typography>
              </div>
            }
            parentMenuOpen={!!open}
            onClick={() => {}}
            rightIcon={<RightChevronIcon className="ml-auto" />}
          >
            {mappedStandardFields.map((mappableField, mappableFieldIndex) => (
              <MenuItem
                key={mappableField.displayName}
                onClick={() => {
                  BULK_IMPORT_HELPERS.matchMappableField(
                    mappableField,
                    newHeader,
                    loadedListingFieldsObj,
                    setLoadedListingFieldsObj,
                    mappedStandardFields,
                    setMappedStandardFields,
                    mappableFieldIndex
                  )
                }}
                style={{
                  width: '240px',
                  minWidth: '240px',
                  paddingLeft: '8px',
                  paddingRight: '12px',
                  height: '36px',
                }}
              >
                <div className="flex w-8 mr-2">
                  {React.createElement(mappableField.icon, {
                    className: 'm-auto',
                  })}
                </div>
                <Typography variant="h3">
                  {mappableField.displayName}
                </Typography>
                {mappableField.match === newHeader.index && (
                  <MenuSelectedItemIcon className="ml-auto text-[#666]" />
                )}
              </MenuItem>
            ))}
          </NestedMenuItem>,
        ]}

        {/* Option to convert the reserved field to a custom field (ie: Date Available to Random Date) */}
        {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
          <MenuItem
            key="convertToCustomFieldKey"
            disableGutters
            onClick={() => {
              if (!newHeaderError) {
                updateLoadedListingFields(newHeader.index, {
                  ...newHeader,
                  match: null,
                  reserved: null,
                })
                // Unset any matches on our standard fields
                const newMappedStandardFields = mappedStandardFields.map(
                  (mappedStandardField) => {
                    if (mappedStandardField.match === newHeader.index) {
                      return { ...mappedStandardField, match: null }
                    }
                    return mappedStandardField
                  }
                )
                setMappedStandardFields(newMappedStandardFields)
                closeMenu()
              }
            }}
            className="px-3 items-center"
            disabled={!!newHeaderError}
          >
            <CustomIcon className="mr-3 text-[#111]" />
            <Typography variant="h3">Convert to Custom Field</Typography>
          </MenuItem>
        )}

        {/* Option to remove a field */}
        {!newHeader.disableAllEditing && [
          <div
            key="fieldMatchingKey"
            className="text-[#666] pb-1.5 pt-5 px-3 items-center"
          >
            <Typography variant="h3" className="leading-3 font-med">
              Remove
            </Typography>
          </div>,
          <MenuItem
            key="removeColumn"
            disableGutters
            onClick={() => {
              setRemoveColumnHeader(newHeader)
            }}
            className="px-3 items-center"
          >
            <DeleteIcon className="mr-3 text-[#111]" />
            <Typography variant="h3">Delete Column</Typography>
          </MenuItem>,
        ]}
      </Menu>
    </TableCell>
  )
}

const BuildingFieldRow = ({
  index,
  rowCount,
  orderedListingFields,
  listing,
  validatedListings,
  setValidatedListings,
  csvHeaderIndexLookup,
}) => {
  const filteredOrderedListingFields = orderedListingFields.filter(
    (header) => listing[header.index] !== undefined
  )

  return (
    <TableRow>
      {filteredOrderedListingFields.map((header, cellIndex) => {
        const cell = listing[header.index]
        const DisplayCell =
          cellComponentMap[header.fieldDataType.id] ?? StringCell

        const isDescription = header.displayName === 'Building Notes'
        const isAddress = header.displayName === 'Address'

        return (
          <TableCell
            key={`${header.index}-${header.fieldDataType.id}`}
            className={clsx(
              'w-[100px] py-3 px-4 h-20 bg-white',
              'border-0 border-solid border-[#E0E0E0] border-l border-t',
              'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
              index === rowCount - 1 && 'border-b',
              isDescription && 'max-w-[344px]',
              isAddress && 'min-w-[260px] text-left',
              cellIndex === 1 && 'border-l-0'
            )}
          >
            <Tooltip
              title={cell.error || ''}
              disableFocusListener
              disableTouchListener
              classes={{
                tooltipPlacementBottom: 'my-2 mx-0',
              }}
            >
              <div
                className="flex items-center max-h-14"
                style={{
                  backgroundColor: cell.error ? 'rgba(221, 66, 26, .15)' : '',
                  color: cell.error ? '#DD421A' : '',
                }}
              >
                <DisplayCell
                  cell={cell}
                  header={header}
                  listing={listing}
                  index={index}
                  validatedListings={validatedListings}
                  setValidatedListings={setValidatedListings}
                  csvHeaderIndexLookup={csvHeaderIndexLookup}
                />
              </div>
            </Tooltip>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const SpaceFieldRow = ({
  orderedListingFields,
  listing: buildingRow,
  validatedListings,
  setValidatedListings,
  csvHeaderIndexLookup,
}) => {
  const filteredOrderedListingFields = orderedListingFields.filter(
    (header) => buildingRow.listings[0][header.index] !== undefined
  )
  const { address, listings } = buildingRow
  const listingCount = listings.length

  return (
    <Fragment>
      <TableRow>
        <TableCell
          className={clsx(
            'h-[84px] pt-[36px] pb-5 pl-0 whitespace-nowrap border-0',
            'sticky left-8 z-[1] bg-white border-r-2'
          )}
        >
          <Typography
            variant="boldText"
            className="font-semibold text-sm leading-7"
          >
            {address}
          </Typography>
        </TableCell>
        <TableCell
          colSpan={filteredOrderedListingFields.length - 1}
          className="border-0"
        />
      </TableRow>

      {listings.map((listing, listingIndex) => {
        const { originalIndex } =
          listing[BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index]

        return (
          <TableRow key={`${address}-${originalIndex}`}>
            {filteredOrderedListingFields.map((header, cellIndex) => {
              const cell = listing[header.index]
              const DisplayCell =
                cellComponentMap[header.fieldDataType.id] ?? StringCell

              const isSpaceNotes = header.displayName === 'Space Notes'

              return (
                <TableCell
                  key={`${header.index}-${header.fieldDataType.id}`}
                  className={clsx(
                    'w-[100px] py-3 px-4 h-20 bg-white',
                    listingIndex === listingCount - 1 && 'border-b',
                    'border-0 border-solid border-[#E0E0E0] border-l border-t',
                    'first:sticky first:left-8 first:z-[1] first:border-r-2 last:border-r',
                    listingIndex === 0 && 'first:rounded-tl last:rounded-tr',
                    isSpaceNotes && 'max-w-[344px]',
                    cellIndex === 1 && 'border-l-0'
                  )}
                >
                  <Tooltip
                    title={cell.error || ''}
                    disableFocusListener
                    disableTouchListener
                    classes={{
                      tooltipPlacementBottom: 'my-2 mx-0',
                    }}
                  >
                    <div
                      className="flex items-center max-h-14"
                      style={{
                        backgroundColor: cell.error
                          ? 'rgba(221, 66, 26, .15)'
                          : '',
                        color: cell.error ? '#DD421A' : '',
                      }}
                    >
                      <DisplayCell
                        cell={cell}
                        header={header}
                        listing={listing}
                        index={originalIndex}
                        validatedListings={validatedListings}
                        setValidatedListings={setValidatedListings}
                        csvHeaderIndexLookup={csvHeaderIndexLookup}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </Fragment>
  )
}

const cellComponentMap = {
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id]: AddressFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id]: NumericFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id]: DateFieldCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id]: ListSemicolonCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id]:
    MultilineStringCell,
  [BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id]: StringCell,
}

const formatValueForBackend = (value, fieldDataTypeId) => {
  if (
    value &&
    fieldDataTypeId === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id
  ) {
    return format(new Date(value.toDateString()), 'yyyy-MM-dd')
  }
  return value
}

const getDataForSave = ({
  survey,
  loadedListingFieldsObj,
  validatedListings,
}) => {
  const allFields = Object.values(BULK_IMPORT_CONSTANTS.FIELDS)
  const specialFields = allFields.filter((field) => field.standard)

  const allCustomFields = new Map()

  const listings = validatedListings.map((validatedListing) => {
    const addressFieldValue =
      validatedListing[BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index].value
        .GOOGLE_ADDRESS

    // Go through each field, and if its one of our special listing/building fields, then grab that value so we can map it [O(n^2), not great]
    // Keep track of the non-custom field indices
    const matchedFieldIndices = new Set()
    const matchedListingFields = {}
    const matchedBuildingFields = {}
    // For each field
    Object.entries(loadedListingFieldsObj).forEach(([key, field]) => {
      // For each special field, check if the field matches any of the special fields
      specialFields.forEach((specialField) => {
        if (
          caseInsensitiveCompare(field.displayName, specialField.displayName)
        ) {
          matchedFieldIndices.add(key)
          const matchedValue = formatValueForBackend(
            validatedListing[key].value || null,
            field.fieldDataType.id
          )
          if (
            specialField.fieldType.id ===
            BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
          ) {
            matchedBuildingFields[specialField.modelFieldName] = matchedValue
          } else {
            matchedListingFields[specialField.modelFieldName] = matchedValue
          }
        }
      })
    })

    const listingCustomFields = []
    const buildingCustomFields = []
    Object.entries(validatedListing).forEach(
      ([fieldIndex, fieldValue], index) => {
        const field = loadedListingFieldsObj[fieldIndex]

        // First, format the value for saving on backend
        const value = formatValueForBackend(
          fieldValue.value || null,
          field.fieldDataType.id
        )

        // Go through and get each custom field, formatted.
        // Skip the non-custom fields we've already added
        if (
          !matchedFieldIndices.has(fieldIndex) &&
          Number(fieldIndex) !== BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index
        ) {
          if (value !== undefined && value !== null && value !== '') {
            const customField = {
              name: field.displayName,
              value: value || null,
              type: field.fieldType.id,
              data_type: field.fieldDataType.id,
            }

            if (
              field.fieldType.id ===
              BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
            ) {
              buildingCustomFields.push(customField)
            } else {
              listingCustomFields.push(customField)
            }
          }

          // When using the new custom fields, only add the custom fields that we haven't already added
          // We only need one instance of each as the definition not all the values we have inside the current loop
          if (!allCustomFields.has(field.displayName)) {
            allCustomFields.set(field.displayName, {
              label: field.displayName,
              dataType: field.fieldDataType.id,
              fieldType: field.fieldType.id,
              order: index,
            })
          }
        }
      }
    )

    // Put together the listing and building data for the request
    const listingFormattedToSave = {
      ...matchedListingFields,
      custom_fields: listingCustomFields,
      survey_id: survey.id,
    }

    const buildingFormattedToSave = {
      ...matchedBuildingFields,
      custom_fields: buildingCustomFields,
      // address
      address: addressFieldValue.address,
      city: addressFieldValue.city,
      state: addressFieldValue.state,
      zipcode: addressFieldValue.zipcode,
      country: addressFieldValue.country,
      latitude: addressFieldValue.latitude,
      longitude: addressFieldValue.longitude,
    }

    return {
      listing: listingFormattedToSave,
      building: buildingFormattedToSave,
    }
  })

  return { listings, customFields: Array.from(allCustomFields.values()) }
}
